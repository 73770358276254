import { FC } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../setup";

interface AuthorizeAccessProps {
    permissionKey: string,
    allowAccess?: boolean
}

const AuthorizeAccess: FC<AuthorizeAccessProps> = ({ permissionKey, allowAccess, children }) => {
    const userPermissions = useSelector<RootState, string[]>(({ auth }) => auth.userPermissions!);

    if (allowAccess || userPermissions.findIndex(p => p == permissionKey) > -1)
        return <>{children}</>;
    else
        return <></>;
}


export { AuthorizeAccess }
