import { createRef, useEffect, useRef, useState } from "react";
import { Button, Container, Modal } from "react-bootstrap";
import { Icons } from "../Helpers/Icons";
import { Buttons } from "./Buttons";
import { ButtonSpinner } from "./ButtonSpinner";

interface ModalProps {
    show: boolean;
    title: string;
    onSave?: () => void;
    onClose: () => void;
    saving?: boolean;
    showSaving?: boolean;
    size?: "sm" | "lg" | "xl";
    saveDisabled?: boolean;
}

const Modals: React.FC<ModalProps> = (props) => {
    const { show, title, onSave, onClose, saving, children, showSaving, size, saveDisabled: saveDisabled } = props;
    let parentWrapper = document.getElementById("kt_wrapper");

    Modals.defaultProps = {
        showSaving: true,
        size: "lg"
    };

    return (
        <>
            <Modal
                show={show}
                size={size}
                onHide={onClose}
                container={parentWrapper}
                backdrop={"static"}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="show-grid">
                    <Container>
                        {children}
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    {showSaving &&
                        <ButtonSpinner
                            onClick={onSave}
                            text="Save Changes"
                            variant="primary"
                            saving={saving!}
                            disabled={saveDisabled}
                        />
                    }
                    <Buttons variant="danger" onClick={onClose} disabled={saving} icon={Icons.Times}>Close</Buttons>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export { Modals }
