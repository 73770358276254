import React, { FC } from "react";
import clsx from 'clsx'
import { Button, Form } from "react-bootstrap";
import AsyncSelect from "react-select/async";
import { OptionModel } from "../Models/OptionModel";
import _ from "lodash";

interface FormGroupSwitchProps {
    label: string;
    controlId: string;
    className?: string;
    name: string;
    isInvalidError?: string | undefined;
    checked: boolean;
    disabled?: boolean;
    onChange: (e: any) => void;
}

const FormGroupSwitch: React.FC<FormGroupSwitchProps> = (props) => {
    let { label, controlId, className, name, isInvalidError, checked, disabled, onChange, children } = props;

    return (
        <>
            <Form.Group className={className} controlId={controlId}>
                <Form.Switch
                    defaultChecked={checked}
                    name={name}
                    label={label}
                    onChange={onChange}
                    disabled={disabled}
                />
            </Form.Group>
        </>
    );
}

export { FormGroupSwitch }