import { put, select, takeLatest } from "redux-saga/effects";
import { showToastrErrors } from "../../../@Common/Helpers/Toastr";
import { ActionWithPayload } from "../../../@Common/Interfaces/ActionWithPayload";
import { ServiceResponse } from "../../../@Common/Interfaces/ServiceResponse";
import { DocumentSigningClientReadModel } from "../models/DocumentSigningClientReadModel";
import { getClientDocumentSigningDetails, getIsClientDocumentSigningIdValid } from "./DocumentSigningClientActions";
import { DocumentSigningClientVerificationModel } from "../models/DocumentSigningClientVerificationModel";
import { IAuthState } from "../../core/auth";

export const actionTypes = {
    VerificationSigningIdRequested: '[Verification Signing Id] Action',
    CerificationSigningIdLoaded: '[Verification Signing Id] API',
    ClientDocumentSigningDetailsRequested: '[Request Client Document Signing Details] Action',
    ClientDocumentSigningDetailsLoaded: '[Loaded Client Document Signing Details] API',
    DisplayError: '[Display Error]'
}

export interface IDocumentSigningClientState {
    loading: boolean,
    retrievingData: boolean,
    validSigningId: boolean,
    documentSigningDetails?: DocumentSigningClientReadModel,
    errorMessages?: string[]
}

const initialDocumentSigningClientState: IDocumentSigningClientState = {
    loading: false,
    retrievingData: false,
    validSigningId: false
}

export const reducer = (state: IDocumentSigningClientState = initialDocumentSigningClientState, action: ActionWithPayload<IDocumentSigningClientState>): IDocumentSigningClientState => {
    switch (action.type) {

        case actionTypes.VerificationSigningIdRequested: {
            return {
                ...state,
                loading: true,
                errorMessages: undefined
            };
        }

        case actionTypes.CerificationSigningIdLoaded: {
            return {
                ...state,
                loading: false,
                validSigningId: action.payload?.validSigningId!
            };
        }

        case actionTypes.ClientDocumentSigningDetailsRequested: {
            return {
                ...state,
                retrievingData: true,
                errorMessages: undefined
            };
        }

        case actionTypes.ClientDocumentSigningDetailsLoaded: {
            return {
                ...state,
                retrievingData: false,
                documentSigningDetails: action.payload?.documentSigningDetails
            };
        }

        case actionTypes.DisplayError: {
            return {
                ...state,
                loading: false,
                retrievingData: false,
                errorMessages: action.payload?.errorMessages
            };
        }

        default:
            return state
    }
}

export const actions = {
    requestVerficationOfSignatureId: (signingId: string) => ({ type: actionTypes.VerificationSigningIdRequested, payload: { signingId } }),
    fulfillVerficationOfSignatureId: (valid: boolean) => ({ type: actionTypes.CerificationSigningIdLoaded, payload: { validSigningId: valid } }),
    requestClientDocumentSigningDetails: (verificationDetails: DocumentSigningClientVerificationModel) => ({ type: actionTypes.ClientDocumentSigningDetailsRequested, payload: { verificationDetails } }),
    fulfillClientDocumentSigningDetails: (data: DocumentSigningClientReadModel) => ({ type: actionTypes.ClientDocumentSigningDetailsLoaded, payload: { documentSigningDetails: data } }),
    displayErrors: (errorMessages: string[]) => ({ type: actionTypes.DisplayError, payload: { errorMessages } }),
}

export function* saga() {
    yield takeLatest(actionTypes.VerificationSigningIdRequested, function* verificationRequested(action: any) {
        let signingId: string = action.payload.signingId;
        const response: ServiceResponse<boolean> = yield getIsClientDocumentSigningIdValid(signingId);

        let isEnglandUser: boolean = false;

        try {
            let auth: IAuthState = yield select((state) => state.auth);
            isEnglandUser = (auth.user !== undefined);
        }
        catch (err) { }

        if (response.isSuccess) {
            yield put(actions.fulfillVerficationOfSignatureId(response.data!));

            if (isEnglandUser)
                yield put(actions.requestClientDocumentSigningDetails({ signingId }));
        } else {
            yield put(actions.displayErrors(response.errors));
        }
    })

    yield takeLatest(actionTypes.ClientDocumentSigningDetailsRequested, function* clientDetailsRequested(action: any) {
        let verificationDetails: DocumentSigningClientVerificationModel = action.payload.verificationDetails;
        const response: ServiceResponse<DocumentSigningClientReadModel> = yield getClientDocumentSigningDetails(verificationDetails);

        if (response.isSuccess) {
            yield put(actions.fulfillClientDocumentSigningDetails(response.data!));
        } else {
            yield put(actions.displayErrors(response.errors));
        }
    })
}