import { all } from 'redux-saga/effects'
import { combineReducers } from 'redux'

import * as auth from '../../app/core/auth'
import * as menu from '../../_metronic/layout/components/aside'
import { reducer as peopleReducer, saga as peopleSaga } from '../../app/core/people/redux/PeopleRedux';
import * as profile from '../../app/core/profile'
import * as onlinePayment from '../../app/core/onlinePayment'
import * as auditLogs from '../../app/core/auditLogs'

import * as subscription from '../../app/subscription'
import * as subscriptionDashboard from '../../app/subscriptionDashboard'
import * as subscriptionRenewal from '../../app/subscriptionRenewal'
import * as subscriptionRenewalDashboard from '../../app/subscriptionRenewalDashboard'
import * as subscriptionRenewalClient from '../../app/subscriptionRenewalClient'
import * as documentSigningDashboard from '../../app/documentSigningDashboard'
import * as documentSigningClient from '../../app/documentSigningClient'

export const rootReducer = combineReducers({
  auth: auth.reducer,
  menu: menu.reducer,
  people: peopleReducer,
  profile: profile.reducer,
  subscription: subscription.reducer,
  onlinePayment: onlinePayment.reducer,
  auditLogs: auditLogs.reducer,

  subscriptionDashboard: subscriptionDashboard.reducer,
  subscriptionRenewal: subscriptionRenewal.reducer,
  subscriptionRenewalDashboard: subscriptionRenewalDashboard.reducer,
  subscriptionRenewalClient: subscriptionRenewalClient.reducer,
  documentSigningDashboard: documentSigningDashboard.reducer,
  documentSigningClient: documentSigningClient.reducer
})

export type RootState = ReturnType<typeof rootReducer>

export function* rootSaga() {
  yield all([
    auth.saga(),
    menu.saga(),
    peopleSaga(),
    profile.saga(),
    subscription.saga(),
    onlinePayment.saga(),
    auditLogs.saga(),

    subscriptionDashboard.saga(),
    subscriptionRenewal.saga(),
    subscriptionRenewalDashboard.saga(),
    subscriptionRenewalClient.saga(),
    documentSigningDashboard.saga(),
    documentSigningClient.saga()
  ])
}