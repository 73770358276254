import { put, takeLatest } from "redux-saga/effects";
import { showToastrErrors } from "../../../@Common/Helpers/Toastr";
import { ActionWithPayload } from "../../../@Common/Interfaces/ActionWithPayload";
import { ServiceResponse } from "../../../@Common/Interfaces/ServiceResponse";
import { DocumentSigningReadModel } from "../models/DocumentSigningReadModel";
import { getLatestDocumentSigningByClientDetails } from "./DocumentSigningDashboardActions";

export const actionTypes = {
    ShowDocumentSigningModal: '[Document Signing Modal Show] Action',
    HideDocumentSigningModal: '[Document Signing Modal Hide] Action',
    RefreshTableRequested: '[Request Refresh Table] Action',
    RefreshTableUpdated: '[Updated Refresh Table] Action',
    ClientDetailsRequested: '[Request Client Details] Action',
    DocumentSigningDetailsLoaded: '[Request Document Signing Details] API',
}

export interface IDocumentSigningDashboardState {
    loading: boolean,
    loadingDetails: boolean,
    refreshTable?: boolean,
    showDocumentSigningModal: boolean,
    matchedDocumentSigningRecord?: DocumentSigningReadModel,
}

const initialDocumentSigningDashboardState: IDocumentSigningDashboardState = {
    loading: false,
    loadingDetails: false,
    showDocumentSigningModal: false
}

export const reducer = (state: IDocumentSigningDashboardState = initialDocumentSigningDashboardState, action: ActionWithPayload<IDocumentSigningDashboardState>): IDocumentSigningDashboardState => {
    switch (action.type) {

        case actionTypes.ShowDocumentSigningModal: {
            return { ...state, showDocumentSigningModal: true, matchedDocumentSigningRecord: undefined };
        }

        case actionTypes.HideDocumentSigningModal: {
            return { ...state, showDocumentSigningModal: false };
        }

        case actionTypes.RefreshTableRequested: {
            return { ...state, refreshTable: true };
        }

        case actionTypes.RefreshTableUpdated: {
            return { ...state, refreshTable: false };
        }

        case actionTypes.ClientDetailsRequested: {
            return { ...state, loadingDetails: true };
        }

        case actionTypes.DocumentSigningDetailsLoaded: {
            return {
                ...state,
                loadingDetails: false,
                matchedDocumentSigningRecord: action.payload?.matchedDocumentSigningRecord!
            };
        }

        default:
            return state
    }
}

export const actions = {
    showDocumentSigningModal: (id?: number) => ({ type: actionTypes.ShowDocumentSigningModal, payload: {} }),
    hideDocumentSigningModal: () => ({ type: actionTypes.HideDocumentSigningModal, payload: {} }),
    refreshTable: () => ({ type: actionTypes.RefreshTableRequested, payload: {} }),
    refreshTableUpdated: () => ({ type: actionTypes.RefreshTableUpdated, payload: {} }),
    requestClientDetails: (policyClaimNumber: string) => ({ type: actionTypes.ClientDetailsRequested, payload: { policyClaimNumber } }),
    fulfillDocumentSigningClientDetails: (documentSigning: DocumentSigningReadModel) => ({ type: actionTypes.DocumentSigningDetailsLoaded, payload: { matchedDocumentSigningRecord: documentSigning } }),
}

export function* saga() {
    yield takeLatest(actionTypes.RefreshTableRequested, function* requested(action: any) {
        yield put(actions.refreshTableUpdated());
    })

    yield takeLatest(actionTypes.ClientDetailsRequested, function* detailsRequested(action: any) {
        let policyClaimNumber: string = action.payload.policyClaimNumber;
        const response: ServiceResponse<DocumentSigningReadModel> = yield getLatestDocumentSigningByClientDetails(policyClaimNumber);

        if (response.isSuccess) {
            yield put(actions.fulfillDocumentSigningClientDetails(response.data!));
        } else {
            showToastrErrors(response.errors);
        }
    })
}