import { Navigate, Routes, Route, Outlet } from 'react-router-dom'
import { AnonymousMasterLayout } from '../../_metronic/layout/AnonymousMasterLayout'
import ClientDocumentSigning from './components/ClientDocumentSigning'

const DocumentSigningClientPage = () => (
    <Routes>
        <Route element={<AnonymousMasterLayout />}>
            <Route
                element={
                    <>
                        <Outlet />
                    </>
                }
            >
                <Route
                    path="/"
                    element={
                        <>
                            <ClientDocumentSigning />
                        </>
                    }
                />
            </Route>
            <Route path='*' element={<Navigate to='/error/404' />} />
        </Route>
    </Routes>
)

export default DocumentSigningClientPage
