/* eslint-disable react/jsx-no-target-blank */
import React, { FC, Fragment, useEffect, useRef, useState } from 'react'
import * as documentSigningDashboardRedux from '../redux/DocumentSigningDashboardRedux'
import { connect, ConnectedProps, useDispatch } from 'react-redux'
import { Alert, Badge, Col, Container, Form, Row, Spinner, Toast, ToastContainer } from 'react-bootstrap'
import { Formik, Field, useFormikContext, FormikProps, FieldArray, FieldArrayRenderProps, setNestedObjectValues } from 'formik';
import * as Yup from 'yup'
import { RootState } from '../../../setup';
import { Modals } from '../../../@Common/UIComponents/Modals';
import { Loader } from '../../../@Common/UIComponents/Loader';
import { Cards } from '../../../@Common/UIComponents/Cards';
import moment from 'moment';
import { InstallmentStatuses } from '../../subscription/enums/InstallmentStatuses';
import { SubscriptionStatuses } from '../../subscription/enums/SubscriptionStatuses';
import { Icons } from '../../../@Common/Helpers/Icons';
import clsx from 'clsx';
import { Buttons } from '../../../@Common/UIComponents/Buttons';
import { ConfirmationModal } from '../../../@Common/UIComponents/ConfirmationModal';
import { AuthorizeAccess } from '../../../@Common/UIComponents/AuthorizeAccess';
import { AuthorizeAccessBlock } from '../../../@Common/UIComponents/AuthorizeAccessBlock';
import { showToastrError, showToastrErrors, showToastrInfo, showToastrSuccess } from '../../../@Common/Helpers/Toastr';
import { FormGroupComponent } from '../../../@Common/FormComponents/FormGroupComponent';
import { arrayBufferToBase64, b64toBlob, nameOf, readUploadedFileAsByteArray } from '../../../@Common/Helpers/Utilities';
import { FileModel } from '../../../@Common/Models/FileModel';
import { usePdf } from '@mikecousins/react-pdf';
import { DocumentSigningSignatureLocationWriteModel, DocumentSigningWriteModel } from '../models/DocumentSigningWriteModel';
import { IDTypes } from '../../subscription/enums/IDTypes';
import { InsuranceTypes } from '../../subscription/enums/InsuranceTypes';
import { FormGroupSelect } from '../../../@Common/FormComponents/FormGroupSelect';
import { getIDTypes, getInsuranceTypes } from '../../subscription/redux/SubscriptionActions';
import { AsyncDropdown } from '../../../@Common/FormComponents/AsyncDropdown';
import { createDocumentSigning, getDocumentTypes } from '../redux/DocumentSigningDashboardActions';
import { FormGroupAsyncSelect } from '../../../@Common/FormComponents/FormGroupAsyncSelect';
import { FormGroupSwitch } from '../../../@Common/FormComponents/FormGroupSwitch';
import { SigningDocumentTypes } from '../enums/SigningDocumentTypes';
import { generatePath, useNavigate } from 'react-router';

const mapState = (state: RootState) => ({ documentSigningDashboard: state.documentSigningDashboard })
const connector = connect(mapState, documentSigningDashboardRedux.actions)
type PropsFromRedux = ConnectedProps<typeof connector>

const DocumentSigningModal: FC<PropsFromRedux> = ({ documentSigningDashboard }) => {
    const [isSaving, setIsSaving] = useState(false)
    const [showSaveConfirm, setShowSaveConfirm] = useState(false)
    const [pdfUploaded, setPdfUploaded] = useState(false)

    const dummyFileLocation = "/media/files/dummy_upload_pdf.pdf";

    const dispatch = useDispatch()
    const navigate = useNavigate();

    // PDF State
    const [currentPage, setCurrentPage] = useState(1);
    const [currentPDFFile, setCurrentPDFFile] = useState<string>(dummyFileLocation);
    const [signatures, setSignatures] = useState<DocumentSigningSignatureLocationWriteModel[]>([]);

    const pdfCanvasRef = useRef<any>(null); // PDF Canvas
    const signatureCanvasRef = useRef<HTMLCanvasElement | null>(null); // Overlay Canvas
    const getPDFCanvasRef = () => (pdfCanvasRef.current as any);
    const getSignatureCanvasRef = () => (signatureCanvasRef.current as any);

    // DPI is 72 (scale = 1)
    let pdf = usePdf({
        file: currentPDFFile,
        page: currentPage,
        canvasRef: pdfCanvasRef,
        onPageRenderSuccess: (page: any) => {
            updateSignatureCanvasSize();
            drawSignatureAnnotations();

            // eslint-disable-next-line react-hooks/exhaustive-deps
        },
        onDocumentLoadSuccess: () => {
            setCurrentPage(1);
            setPdfUploaded(true);
        }
    });

    const { pdfDocument, pdfPage } = pdf;

    const { showDocumentSigningModal, matchedDocumentSigningRecord, loadingDetails } = documentSigningDashboard;

    // Form
    const documentFileId = "formDocumentFile";
    const formikRef = useRef(null);
    const getFormikForm = () => (formikRef.current as any);
    let formikProps: FormikProps<DocumentSigningWriteModel>;
    let signatureArrayHelperProps: FieldArrayRenderProps;

    const signingSchema: Yup.SchemaOf<DocumentSigningWriteModel> = Yup.object({
        policyClaimNumber: Yup.string()
            .label("Policy / Claim Number")
            .max(20)
            .required(),
        idType: Yup
            .mixed()
            .label("ID Type")
            .required()
            .oneOf([IDTypes.Passport, IDTypes.IDCard, IDTypes.CRN], (obj) => `${obj.path} is missing or invalid`)
            .defined(),
        idNumber: Yup.string()
            .label("ID Number")
            .max(20)
            .required(),
        name: Yup.string()
            .label("Name")
            .max(100)
            .required(),
        surname: Yup.string()
            .label("Surname")
            .max(100)
            .required(),
        email: Yup.string()
            .label("Email")
            .email()
            .max(100)
            .when("sendAsEmail", {
                is: true,
                then: Yup.string().required("Email is required since Send As Email is checked")
            }),
        sendAsEmail: Yup.boolean()
            .label("Send As Email")
            .required(),
        documentType: Yup.mixed()
            .label("Form Type")
            .required()
            .oneOf([
                SigningDocumentTypes.ProposalForm,
                SigningDocumentTypes.MeetingForm,
                SigningDocumentTypes.ClaimForm,
                SigningDocumentTypes.Other
            ], (obj) => `${obj.path} is missing or invalid`)
            .defined(),
        documentFile: Yup.object().shape({})
            .label("Document")
            .required(),
        signatureLocations: Yup.array()
            .of<any>(
                Yup.object().shape({})
                    .required(),
            )
            .label("Signatures")
            .min(1, "At least one signature needs to be specified")
            .required()
    });

    useEffect(() => {
        if (showDocumentSigningModal) {
            formikProps.resetForm();
            setSignatures([]);
            setPdfUploaded(false);
            setCurrentPage(1);
        }
    }, [showDocumentSigningModal])

    useEffect(() => {
        let pdfCanvasRef = getPDFCanvasRef();

        if (!pdfCanvasRef) return;

        const ctx = pdfCanvasRef.getContext("2d");

        if (!ctx) return;

        drawSignatureAnnotations();
    }, [currentPage, signatures]);

    useEffect(() => {
        updateSignatureCanvasSize();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPDFFile]);

    const addSignature = (x: number, y: number, pageNumber: number): DocumentSigningSignatureLocationWriteModel => {
        const newSignature: DocumentSigningSignatureLocationWriteModel = { x, y, pageNumber };
        setSignatures((prev) => [...prev, newSignature]);
        return newSignature;
    }

    const updateSignatureCanvasSize = () => {
        let pdfCanvasRef = getPDFCanvasRef();

        if (!pdfCanvasRef) return;

        var canvas = document.getElementById('signatureCanvas') as any;
        canvas.width = pdfCanvasRef.clientWidth;
        canvas.height = pdfCanvasRef.clientHeight;
    }

    const onCloseDetailsModal = () => {
        dispatch(documentSigningDashboardRedux.actions.hideDocumentSigningModal());
    }

    const onConfirmEditDetails = async () => {
        getFormikForm().submitForm();
    }

    const setUploadedFile = async (file: any, props: FormikProps<DocumentSigningWriteModel>, name: string) => {
        setPdfUploaded(false);

        if (file) {
            if (file.type !== "application/pdf") {
                showToastrError("Please upload a valid PDF file");
                props.setFieldValue(`${name}`, undefined);
                clearPDFFile();
            } else if (file.size / (1024 * 1024) > 2) {
                showToastrError("PDF file cannot be more than 2MB");
                props.setFieldValue(`${name}`, undefined);
                clearPDFFile();
            } else {
                let fileContent: any = await readUploadedFileAsByteArray(file);

                let fileContentBase64 = arrayBufferToBase64(fileContent);

                var fileUploadModel = new FileModel(
                    file.name,
                    file.size,
                    fileContentBase64
                );

                setCurrentPDFFile(URL.createObjectURL(file));

                setCurrentPage(1);
                setSignatures([]);
                formikProps.values.signatureLocations = [];
                props.setFieldValue(`${name}`, fileUploadModel);
            }
        } else {
            props.setFieldValue(`${name}`, undefined);
            clearPDFFile();
        }
    }

    const handleAnnotationClick = (event: React.MouseEvent<HTMLCanvasElement>) => {
        if (!pdfUploaded) return;

        let signatureCanvasRef = getSignatureCanvasRef();

        if (!signatureCanvasRef) return;

        const rect = signatureCanvasRef.getBoundingClientRect();
        const scaleX = signatureCanvasRef.width / rect.width;
        const scaleY = signatureCanvasRef.height / rect.height;

        let x = (event.clientX - rect.left) * scaleX;
        const y = (event.clientY - rect.top) * scaleY;

        const moveXToLeft = 55;

        if ((x - moveXToLeft) > 0)
            x = x - moveXToLeft; // Move a bit to the left to have the signature centred

        const newSignature = addSignature(Math.ceil(x), Math.ceil(y), currentPage);
        signatureArrayHelperProps.insert(signatures.length, newSignature as DocumentSigningSignatureLocationWriteModel);
    };

    const drawSignatureAnnotations = () => {
        const canvas = getSignatureCanvasRef();

        if (!canvas) return;

        const ctx = canvas.getContext("2d");

        if (!ctx) return;

        ctx.clearRect(0, 0, canvas.width, canvas.height);

        signatures
            .forEach((sign, i) => {
                if (sign.pageNumber === currentPage) {
                    ctx.fillStyle = "blue";
                    ctx.font = "bold 16px Arial";
                    // ctx.textAlign = 'center';
                    ctx.textBaseline = 'middle';
                    ctx.fillText(`<SIGNATURE ${(i + 1)}>`, sign.x, sign.y);
                }
            });

    };

    const onSignatureRemove = (index: number) => {
        let currentSignatures = signatures;
        currentSignatures.splice(index, 1);
        setSignatures(() => [...currentSignatures]);
        signatureArrayHelperProps.remove(index);
    }

    const clearPDFFile = () => {
        let pdf: any = document!.getElementById(documentFileId)!;
        pdf.value = "";
    }

    const onPolicyClaimNumberBlur = () => {
        let policyClaimNumber = formikProps.values.policyClaimNumber;
        dispatch(documentSigningDashboardRedux.actions.requestClientDetails(policyClaimNumber!))
    }

    useEffect(() => {
        if (formikProps) {
            if (matchedDocumentSigningRecord)
                showToastrInfo("Details loaded from previous signing");

            // Adding timeout due to minor issue with formik
            setTimeout(() => {
                if (matchedDocumentSigningRecord) {
                    formikProps.setFieldValue(`${nameOf<DocumentSigningWriteModel>("name")}`, matchedDocumentSigningRecord ? matchedDocumentSigningRecord.name : "")
                    formikProps.setFieldValue(`${nameOf<DocumentSigningWriteModel>("surname")}`, matchedDocumentSigningRecord ? matchedDocumentSigningRecord.surname : "")
                    formikProps.setFieldValue(`${nameOf<DocumentSigningWriteModel>("idType")}`, matchedDocumentSigningRecord ? matchedDocumentSigningRecord.idType : undefined)
                    formikProps.setFieldValue(`${nameOf<DocumentSigningWriteModel>("idNumber")}`, matchedDocumentSigningRecord ? matchedDocumentSigningRecord.idNumber : "")
                    formikProps.setFieldValue(`${nameOf<DocumentSigningWriteModel>("email")}`, matchedDocumentSigningRecord ? matchedDocumentSigningRecord.email : "")
                }
            }, 1);
        }
    }, [matchedDocumentSigningRecord]);

    return (
        <>
            <Modals
                show={showDocumentSigningModal}
                title={"New Document Signing"}
                onClose={onCloseDetailsModal}
                onSave={() => {
                    getFormikForm().validateForm().then(v => {
                        if (getFormikForm().isValid)
                            setShowSaveConfirm(true)
                        else {
                            // Manually touch each field since validateForm will not do it and so error is not shown
                            getFormikForm().setTouched(setNestedObjectValues(v, true))
                        }
                    });
                }}
                saving={isSaving}
                size={"xl"}
            >
                <>
                    <Formik
                        enableReinitialize={true}
                        innerRef={formikRef}
                        initialValues={{
                            policyClaimNumber: "",
                            email: "",
                            sendAsEmail: false
                        } as DocumentSigningWriteModel}
                        validationSchema={signingSchema}
                        validateOnChange={true}
                        validateOnBlur={false}
                        onSubmit={(values: DocumentSigningWriteModel, { setSubmitting, setStatus, resetForm }) => {
                            setIsSaving(true)
                            setTimeout(async () => {
                                try {
                                    let response = await createDocumentSigning(values);

                                    if (!response.isSuccess) {
                                        showToastrErrors(response.errors);
                                    } else {
                                        showToastrSuccess("Document Signing has been successfully saved");
                                        dispatch(documentSigningDashboardRedux.actions.refreshTable());
                                        dispatch(documentSigningDashboardRedux.actions.hideDocumentSigningModal());
                                        clearPDFFile();
                                        // window.open(`/clientDocumentSigning?signingId=${response.data}`,'_blank')
                                    }

                                    setShowSaveConfirm(false);
                                } catch (e) {
                                    showToastrError('An unexpected error has occured');
                                }

                                setSubmitting(false);
                                setIsSaving(false);
                            }, 1000);
                        }}
                    >
                        {(props) => {
                            formikProps = props;
                            return (
                                <>
                                    <div className='col-12'>
                                        <Form noValidate onSubmit={props.handleSubmit}>
                                            <Cards
                                                border='dark'
                                                title={
                                                    <>
                                                        <h2>Client Information</h2>
                                                    </>
                                                }
                                            >
                                                <div className='mt-5'>
                                                    <FormGroupComponent
                                                        name={`${nameOf<DocumentSigningWriteModel>("policyClaimNumber")}`}
                                                        className="mb-3 col-6"
                                                        controlId="formPolicyClaimNumber"
                                                        label="Policy / Claim Number"
                                                        value={props.values.policyClaimNumber}
                                                        placeholder="12345678"
                                                        handleBlur={(e) => {
                                                            props.handleBlur(e)
                                                            onPolicyClaimNumberBlur();
                                                        }}
                                                        handleChange={props.handleChange}
                                                        isInvalidError={props.errors.policyClaimNumber}
                                                        touched={props.touched.policyClaimNumber}
                                                        required={true}
                                                        loading={loadingDetails}
                                                    />
                                                    <FormGroupSelect
                                                        className="mb-3 col-6"
                                                        controlId="formIDType"
                                                        label="ID Type"
                                                        name={`${nameOf<DocumentSigningWriteModel>("idType")}`}
                                                        onBlur={props.setFieldTouched}
                                                        onChange={props.setFieldValue}
                                                        value={props.values.idType}
                                                        touched={props.touched.idType}
                                                        isInvalidError={props.errors.idType}
                                                        options={getIDTypes()}
                                                        required={true}
                                                    />
                                                    <FormGroupComponent
                                                        name={`${nameOf<DocumentSigningWriteModel>("idNumber")}`}
                                                        className="mb-3 col-6"
                                                        controlId="formIDNumber"
                                                        label="ID Number"
                                                        value={props.values.idNumber}
                                                        placeholder="123456M"
                                                        handleBlur={props.handleBlur}
                                                        handleChange={props.handleChange}
                                                        isInvalidError={props.errors.idNumber}
                                                        touched={props.touched.idNumber}
                                                        required={true}
                                                    />
                                                    <FormGroupComponent
                                                        name={`${nameOf<DocumentSigningWriteModel>("name")}`}
                                                        className="mb-3 col-12"
                                                        controlId="formName"
                                                        label="Name"
                                                        value={props.values.name}
                                                        placeholder="Joe"
                                                        handleBlur={props.handleBlur}
                                                        handleChange={props.handleChange}
                                                        isInvalidError={props.errors.name}
                                                        touched={props.touched.name}
                                                        required={true}
                                                    />
                                                    <FormGroupComponent
                                                        name={`${nameOf<DocumentSigningWriteModel>("surname")}`}
                                                        className="mb-3 col-12"
                                                        controlId="formSurname"
                                                        label="Surname"
                                                        value={props.values.surname}
                                                        placeholder="Borg"
                                                        handleBlur={props.handleBlur}
                                                        handleChange={props.handleChange}
                                                        isInvalidError={props.errors.surname}
                                                        touched={props.touched.surname}
                                                        required={true}
                                                    />
                                                    <FormGroupComponent
                                                        name={`${nameOf<DocumentSigningWriteModel>("email")}`}
                                                        className="mb-3 col-12"
                                                        controlId="formEmail"
                                                        label="Email"
                                                        value={props.values.email}
                                                        placeholder="email@domain.com"
                                                        handleBlur={props.handleBlur}
                                                        handleChange={props.handleChange}
                                                        isInvalidError={props.errors.email}
                                                        touched={props.touched.email}
                                                        required={false}
                                                    />
                                                    <FormGroupSwitch
                                                        className="mb-5 mt-5"
                                                        controlId={"formSendAsEmail"}
                                                        label={"Send As Email"}
                                                        name={`${nameOf<DocumentSigningWriteModel>("sendAsEmail")}`}
                                                        checked={props.values.sendAsEmail!}
                                                        onChange={props.handleChange}
                                                    />
                                                    <Alert variant="primary">
                                                        <p>
                                                            <i className={Icons.ExclamationTriangle + " me-1"} />
                                                            Tick <b>Send As Email</b> only if you want signing to be done by the client via the link sent in the email
                                                        </p>
                                                    </Alert>
                                                </div>
                                            </Cards>
                                            <Cards
                                                border='dark'
                                                title={
                                                    <>
                                                        <h2>Document & Signature Information</h2>
                                                    </>
                                                }
                                            >
                                                <div className='mt-5'>
                                                    <FormGroupSelect
                                                        className="mb-3 col-6"
                                                        controlId="formDocumentType"
                                                        label="Document Type"
                                                        name={`${nameOf<DocumentSigningWriteModel>("documentType")}`}
                                                        onBlur={props.setFieldTouched}
                                                        onChange={props.setFieldValue}
                                                        value={props.values.documentType}
                                                        touched={props.touched.documentType}
                                                        isInvalidError={props.errors.documentType}
                                                        options={getDocumentTypes()}
                                                    />
                                                    <FormGroupComponent
                                                        name={`${nameOf<DocumentSigningWriteModel>("documentFile")}`}
                                                        className="mb-3 col-12"
                                                        controlId={documentFileId}
                                                        label="Document File"
                                                        type="file"
                                                        handleBlur={props.setFieldTouched}
                                                        handleChange={v => {
                                                            setUploadedFile(v.target.files[0], props, nameOf<DocumentSigningWriteModel>("documentFile"));
                                                        }}
                                                        isInvalidError={props.errors.documentFile}
                                                        touched={props.touched.documentFile}
                                                        required={true}
                                                        accept=".pdf"
                                                    />
                                                    {pdfUploaded &&
                                                        <>
                                                            <div style={{ position: "relative", display: "inline-block", borderStyle: "solid" }}>
                                                                {pdfDocument && (
                                                                    <canvas id="pdfCanvas" ref={pdfCanvasRef} />
                                                                )}

                                                                {/* Transparent Canvas on Top for Annotations */}
                                                                <canvas
                                                                    id="signatureCanvas"
                                                                    ref={signatureCanvasRef}
                                                                    onClick={handleAnnotationClick}
                                                                    style={{
                                                                        position: "absolute",
                                                                        top: 0,
                                                                        left: 0,
                                                                        aspectRatio: "1",
                                                                        pointerEvents: "auto",
                                                                        background: "transparent",
                                                                    }}
                                                                />
                                                            </div>

                                                            {pdfDocument && pdfDocument.numPages &&
                                                                <>
                                                                    <div className="d-flex justify-content-between align-items-center flex-wrap col-6">
                                                                        <div className="d-flex flex-wrap col-12">
                                                                            <Buttons
                                                                                disabled={currentPage === 1}
                                                                                variant="dark"
                                                                                className="btn-icon"
                                                                                onClick={() => { setCurrentPage(currentPage - 1) }}
                                                                                icon={Icons.ArrowLeft}
                                                                                size={"lg"}
                                                                                tooltip="Next">
                                                                            </Buttons>
                                                                            <Buttons
                                                                                disabled={currentPage === pdfDocument.numPages}
                                                                                variant="dark"
                                                                                className="btn-icon"
                                                                                onClick={() => { setCurrentPage(currentPage + 1) }}
                                                                                icon={Icons.ArrowRight}
                                                                                size={"lg"}
                                                                                tooltip="Next">
                                                                            </Buttons>
                                                                            <div className="d-flex align-items-center ms-5">
                                                                                <span className="text-muted">Page {currentPage} of {pdfDocument.numPages}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <Alert variant="primary mt-3">
                                                                        <p>
                                                                            Click on the location within the document to mark where the signature(s) will go. You can move through the pages with the buttons above.
                                                                        </p>
                                                                    </Alert>
                                                                </>
                                                            }

                                                            <hr />

                                                            <h2>Signatures ({props.values.signatureLocations == null ? 0 : props.values.signatureLocations.length})</h2>

                                                            <div style={{ maxHeight: "300px", overflowY: "auto" }}>
                                                                <Container fluid>
                                                                    <FieldArray
                                                                        name={`${nameOf<DocumentSigningWriteModel>("signatureLocations")}`}
                                                                        render={arrayHelpers => {
                                                                            signatureArrayHelperProps = arrayHelpers
                                                                            return (
                                                                                <>
                                                                                    <div className={props.touched.signatureLocations && props.errors.signatureLocations ? "is-invalid" : ""}>
                                                                                        {props.values.signatureLocations && (
                                                                                            props.values.signatureLocations.map((signature, index) => (
                                                                                                <Row className='col-12 mt-5' key={index}>
                                                                                                    <Col sm={6}>
                                                                                                        <b>Signature {index + 1} (Page {signature.pageNumber})</b>
                                                                                                    </Col>
                                                                                                    <Col sm={2}>
                                                                                                        <Buttons
                                                                                                            variant="danger"
                                                                                                            className="btn-icon"
                                                                                                            onClick={() => { onSignatureRemove(index) }}
                                                                                                            icon={Icons.Trash}
                                                                                                            size={"sm"}
                                                                                                            tooltip="Remove Signature">
                                                                                                        </Buttons>
                                                                                                    </Col>
                                                                                                </Row>
                                                                                            ))
                                                                                        )}
                                                                                    </div>
                                                                                    <div className="invalid-feedback">
                                                                                        {props.errors.signatureLocations}
                                                                                    </div>
                                                                                </>
                                                                            )
                                                                        }}
                                                                    />
                                                                </Container>
                                                            </div>
                                                        </>
                                                    }
                                                </div>
                                            </Cards>
                                        </Form>
                                    </div >
                                </>
                            )
                        }}
                    </Formik>
                </>
            </Modals >
            <ConfirmationModal
                title='Save Document Signing'
                question={<>
                    Are you sure you want to proceed?
                </>}
                show={showSaveConfirm}
                saving={isSaving}
                onConfirm={onConfirmEditDetails}
                onClose={() => {
                    setShowSaveConfirm(false);
                }}
            />
        </>
    );
}

export default connector(DocumentSigningModal)