import { getFromServerAPI, postToServerAPI } from '../../../@Common/Helpers/ApiHelper';
import { PaginatedServiceResponse, ServiceResponse } from '../../../@Common/Interfaces/ServiceResponse';
import { GridFilterDto } from '../../../@Common/Models/GridModel';
import { PaginatedResult } from '../../../@Common/Models/PagiantedResult';
import { PendingSubscriptionClientReadModel } from '../../subscriptionRenewalClient/models/PendingSubscriptionClientReadModel';
import { DocumentSigningClientVerificationModel } from '../models/DocumentSigningClientVerificationModel';
import { DocumentSigningSignDocumentModel } from '../models/DocumentSigningSignDocumentModel';

const API_URL = process.env.REACT_APP_API_URL + "/api/v1/documentSigning";

const GET_CLIENT_DOCUMENT_SIGNING_ID_VALID_URL = API_URL + "/GetIsClientDocumentSigningIdValid";
const GET_CLIENT_DOCUMENT_SIGNING_DETAILS_URL = API_URL + "/GetClientDocumentSigningDetails";
const PAGE_ACCESSED_URL = API_URL + "/PageAccessed";
const SIGN_DOCUMENT_URL = API_URL + "/SignDocument";

export const getIsClientDocumentSigningIdValid = async (signingId: string) => {
    let response = await postToServerAPI<ServiceResponse<PendingSubscriptionClientReadModel>>(GET_CLIENT_DOCUMENT_SIGNING_ID_VALID_URL, { signingId });
    return response;
}

export const getClientDocumentSigningDetails = async (verificationDetails: DocumentSigningClientVerificationModel) => {
    let response = await postToServerAPI<ServiceResponse<PendingSubscriptionClientReadModel>>(GET_CLIENT_DOCUMENT_SIGNING_DETAILS_URL, verificationDetails);
    return response;
}

export const pageAccessed = async (signingId: string) => {
    let response = await postToServerAPI<ServiceResponse>(PAGE_ACCESSED_URL, { signingId });
    return response;
}

export const signDocument = async (model: DocumentSigningSignDocumentModel) => {
  let response = await postToServerAPI<ServiceResponse>(SIGN_DOCUMENT_URL, model);
  return response;
}