import { createRef, useEffect, useRef, useState } from "react";
import { Button, Container, Form, InputGroup, Modal, Spinner } from "react-bootstrap";

interface FormGroupComponentProps {
    label: string;
    controlId: string;
    className?: string;
    name?: string;
    handleChange?: (e: any) => void;
    handleBlur?: (e: any) => void;
    value?: any;
    isInvalidError?: any | undefined;
    placeholder?: string;
    autocomplete?: boolean;
    touched?: any | undefined;
    type?: string;
    disabled?: boolean;
    inputGroup?: FormGroupComponentInputGroupProps;
    required?: boolean;
    accept?: string;
    as?: React.ElementType;
    rows?: number;
    loading?: boolean;
}

interface FormGroupComponentInputGroupProps {
    size?: "sm" | "lg"
    text?: string
}

const FormGroupComponent: React.FC<FormGroupComponentProps> = (props) => {
    const { className, controlId, label, name, value, isInvalidError, placeholder, autocomplete = false, touched, type, disabled, inputGroup, required = true, accept, as, rows, handleChange, handleBlur, loading } = props;

    const displayControl = () => {
        return (
            <>
                <div className="position-relative">
                    <Form.Control
                        name={name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={value}
                        isInvalid={touched && !!isInvalidError}
                        placeholder={placeholder}
                        autoComplete={!autocomplete ? "off" : undefined}
                        type={type}
                        disabled={disabled}
                        accept={accept}
                        as={as}
                        rows={rows}
                    />
                    {loading &&
                        <Spinner
                            className="position-absolute top-50 end-0 ms-2"
                            animation="border"
                            size="sm"
                        />
                    }
                    <Form.Control.Feedback type="invalid">
                        {isInvalidError}
                    </Form.Control.Feedback>
                </div>
            </>);
    }

    return (
        <>
            <Form.Group className={className} controlId={controlId}>
                <Form.Label>{label} {required ? <span className="text-danger">*</span> : <></>}</Form.Label>
                {inputGroup &&
                    <InputGroup size={inputGroup.size} className="mb-3">
                        <InputGroup.Text>{inputGroup.text}</InputGroup.Text>
                        {displayControl()}
                    </InputGroup>
                }
                {!inputGroup &&
                    displayControl()
                }
            </Form.Group>
        </>
    );
}

export { FormGroupComponent }
