/* eslint-disable react/jsx-no-target-blank */
import React, { FC, Fragment, useEffect, useRef, useState } from 'react'
import * as documentSigningClientRedux from '../redux/DocumentSigningClientRedux'
import { connect, ConnectedProps, useDispatch } from 'react-redux'
import { Alert, Button, Card, CardGroup, Col, Container, Nav, Row } from 'react-bootstrap'
import { generatePath, useNavigate, useSearchParams } from 'react-router-dom'
import { ErrorBoundary } from '../../../@Common/UIComponents/ErrorBoundary'
import { Loader } from '../../../@Common/UIComponents/Loader'
import { RootState } from '../../../setup/redux/RootReducer'
import { showToastrError, showToastrErrors, showToastrSuccess } from '../../../@Common/Helpers/Toastr'
import moment from 'moment'
import * as Yup from 'yup'
import { Form, Formik } from 'formik'
import { Cards } from '../../../@Common/UIComponents/Cards'
import { ButtonSpinner } from '../../../@Common/UIComponents/ButtonSpinner'
import { FormGroupSelect } from '../../../@Common/FormComponents/FormGroupSelect'
import { b64toBlob, nameOf } from '../../../@Common/Helpers/Utilities'
import { ConfirmationModal } from '../../../@Common/UIComponents/ConfirmationModal'
import { format } from 'numerable';
import { pageAccessed, signDocument } from '../redux/DocumentSigningClientActions'
import { FormGroupComponent } from '../../../@Common/FormComponents/FormGroupComponent'
import { DocumentSigningClientVerificationModel } from '../models/DocumentSigningClientVerificationModel'
import { usePdf } from '@mikecousins/react-pdf'
import { Buttons } from '../../../@Common/UIComponents/Buttons'
import { Icons } from '../../../@Common/Helpers/Icons'
import SignatureCanvas from 'react-signature-canvas'
import trimCanvas from 'trim-canvas'
import { DocumentSigningSignDocumentModel } from '../models/DocumentSigningSignDocumentModel'
import "../css/SigningClient.scss";

const mapState = (state: RootState) => ({ documentSigningClient: state.documentSigningClient, auth: state.auth })
const connector = connect(mapState, documentSigningClientRedux.actions)
type PropsFromRedux = ConnectedProps<typeof connector>

const signingSchema: Yup.SchemaOf<DocumentSigningClientVerificationModel> = Yup.object().shape({
    signingId: Yup.string()
        .label("Signing Id")
        .required(),
    idNumber: Yup.string()
        .label("ID Number")
        .required()
});

const ClientDocumentSigning: FC<PropsFromRedux> = ({ documentSigningClient, auth }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [isSaving, setIsSaving] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [isSavedSuccessful, setIsSavedSuccessful] = useState(false);
    const [documentLoading, setDocumentLoading] = useState(false);
    const [signatureURL, setSignatureURL] = useState<string>();
    const [validatedIdNumber, setValidatedIdNumber] = useState<string>();

    const [searchParams, setSearchParams] = useSearchParams();

    const signingId: string = searchParams.get("signingId") as string;
    const { loading, retrievingData, errorMessages, documentSigningDetails, validSigningId } = documentSigningClient;
    const { user } = auth;

    const formikRef = useRef(null);
    const getFormikForm = () => (formikRef.current as any);

    const dummyFileLocation = "/media/files/dummy_viewing_pdf.pdf";

    // PDF
    const [currentPage, setCurrentPage] = useState(1);
    const [currentPDFFile, setCurrentPDFFile] = useState<string>(dummyFileLocation);

    const pdfCanvasRef = useRef<any>(null); // PDF Canvas
    const getPDFCanvasRef = () => (pdfCanvasRef.current as any);

    // DPI is 72 (scale = 1)
    let pdf = usePdf({
        file: currentPDFFile,
        page: currentPage,
        canvasRef: pdfCanvasRef,
        onDocumentLoadSuccess: () => {
            if (documentSigningDetails)
                setDocumentLoading(false)
        }
    });

    const { pdfDocument, pdfPage } = pdf;

    // Signature
    const signatureCanvas: any = useRef({});
    const signatureCanvasCurrent: any = signatureCanvas.current;

    const isEnglandUser = () => user !== undefined;

    useEffect(() => {
        async function checkDocumentSignature() {
            dispatch(documentSigningClientRedux.actions.requestVerficationOfSignatureId(signingId!));
        }

        if (signingId == null || signingId == '') {
            let path = generatePath("/error/500", {});
            return navigate(path);
        } else
            checkDocumentSignature();
    }, [])

    useEffect(() => {
        if (documentSigningDetails) {
            setDocumentLoading(true)
            pageAccessed(signingId!);
            loadDocument();
        }
    }, [documentSigningDetails])

    const loadDocument = () => {
        let fileblob = b64toBlob(documentSigningDetails?.documentFile!.contents);
        setCurrentPDFFile(URL.createObjectURL(fileblob));
    }

    const onRetrieveData = () => {
        getFormikForm().submitForm();
    }

    const displayRetrievalForm = () =>
        <>
            <Formik
                innerRef={formikRef}
                initialValues={{
                    signingId: signingId,
                    idNumber: ""
                } as DocumentSigningClientVerificationModel}
                validationSchema={signingSchema}
                validateOnChange={true}
                validateOnBlur={false}
                onSubmit={(values: DocumentSigningClientVerificationModel, { setSubmitting, setStatus, resetForm }) => {
                    setIsSaving(true)
                    setValidatedIdNumber(values.idNumber!);

                    setTimeout(async () => {
                        try {
                            dispatch(documentSigningClientRedux.actions.requestClientDocumentSigningDetails(values));
                        } catch (e) {
                            showToastrError('An unexpected error has occured');
                        }

                        setSubmitting(false);
                        setIsSaving(false);
                    }, 1000);
                }}
            >
                {(props) => (
                    <>
                        <Form noValidate onSubmit={props.handleSubmit}>
                            <Cards
                                border='dark'
                                footer={
                                    <>
                                        <div className='d-flex justify-content-start'>
                                            <ButtonSpinner
                                                onClick={onRetrieveData}
                                                variant="primary"
                                                text='Proceed'
                                                saving={isSaving || retrievingData}
                                                className='m-1'
                                                disabled={!props.dirty}
                                            />
                                        </div>
                                    </>
                                }
                            >
                                <>
                                    <FormGroupComponent
                                        name={`${nameOf<DocumentSigningClientVerificationModel>("idNumber")}`}
                                        className="mb-3 col-xs-5 col-md-3 col-lg-3"
                                        controlId="formIDNumber"
                                        label="ID / Passport / CRN Number"
                                        value={props.values.idNumber}
                                        handleBlur={props.handleBlur}
                                        handleChange={props.handleChange}
                                        isInvalidError={props.errors.idNumber}
                                        touched={props.touched.idNumber}
                                        required={true}
                                    />
                                    <Alert variant="primary">
                                        <p>
                                            In order to verify authenticity kindly enter the details above that were used in order to proceed with signing of the document.
                                        </p>
                                    </Alert>
                                </>
                            </Cards>
                        </Form>
                    </>
                )}
            </Formik>
        </>;

    const onSignatureSave = () => {
        setShowConfirmModal(true);
    }

    const clearSignature = () => {
        signatureCanvasCurrent.clear();
        setSignatureURL(undefined);
    }

    const signingArea = () =>
        <>
            <div>
                <SignatureCanvas
                    ref={signatureCanvas}
                    canvasProps={{ className: 'signatureCanvas border border-3 border-dark' }}
                    onEnd={setSignatureData}
                />
            </div>
        </>;

    const displayDocumentAndSignatureArea = () =>
        <>
            <Cards
                border='dark'
                footer={
                    <>
                        <div className='d-flex justify-content-start'>
                            <ButtonSpinner
                                onClick={onSignatureSave}
                                variant="primary"
                                text='Proceed to Sign'
                                saving={isSaving}
                                className='m-1'
                                disabled={signatureURL === undefined}
                                icon={Icons.Check}
                            />
                            <Buttons
                                variant="warning"
                                className="m-1"
                                onClick={clearSignature}
                                icon={Icons.Times}
                                disabled={signatureURL === undefined}                            >
                                Clear Signature
                            </Buttons>
                        </div>
                    </>
                }
            >
                <div style={{ overflowX: "scroll" }}>
                    {pdfDocument && (
                        <canvas id="pdfCanvas" className="border border-dark" ref={pdfCanvasRef} />
                    )}
                </div>

                {pdfDocument && pdfDocument.numPages &&
                    <>
                        <div className="d-flex justify-content-between align-items-center flex-wrap col-6">
                            <div className="d-flex flex-wrap col-12">
                                <Buttons
                                    disabled={currentPage === 1}
                                    variant="dark"
                                    className="btn-icon"
                                    onClick={() => { setCurrentPage(currentPage - 1) }}
                                    icon={Icons.ArrowLeft}
                                    size={"lg"}
                                    tooltip="Next">
                                </Buttons>
                                <Buttons
                                    disabled={currentPage === pdfDocument.numPages}
                                    variant="dark"
                                    className="btn-icon"
                                    onClick={() => { setCurrentPage(currentPage + 1) }}
                                    icon={Icons.ArrowRight}
                                    size={"lg"}
                                    tooltip="Next">
                                </Buttons>
                                <div className="d-flex align-items-center ms-5">
                                    <span className="text-muted">Page {currentPage} of {pdfDocument.numPages}</span>
                                </div>
                            </div>
                        </div>
                        <Alert variant="primary mt-3">
                            <p>
                                You can move through the pages of the document with the arrow buttons. Review the document and sign in the area provided below. Then click Proceed to submit the signature for this document.
                            </p>
                        </Alert>

                        {signingArea()}
                    </>
                }
            </Cards>
        </>;

    const setSignatureData = () => {
        let trimmedCanvas: any = getTrimmedCanvas();
        let imageDataUrl = resizeAndExportCanvas(trimmedCanvas, 200, 100);
        setSignatureURL(imageDataUrl);
    }

    // sigCanvasCurrent.getTrimmedCanvas does not work so use this instead
    const getTrimmedCanvas = (): HTMLCanvasElement => {
        // copy the canvas
        const canvas = signatureCanvasCurrent.getCanvas()
        const copy = document.createElement('canvas')
        copy.width = canvas.width
        copy.height = canvas.height
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        copy.getContext('2d', { willReadFrequently: true })!.drawImage(canvas, 0, 0)
        // then trim it
        return trimCanvas(copy)
    }

    const resizeAndExportCanvas = (canvas, width, height, quality = 1.0) => {
        // Create an offscreen canvas for higher resolution rendering
        const tmpCanvas = document.createElement("canvas");
        const scaleFactor = Math.max(width / canvas.width, height / canvas.height);

        tmpCanvas.width = width * scaleFactor;
        tmpCanvas.height = height * scaleFactor;

        const ctx = tmpCanvas.getContext("2d");

        // Ensure high-quality scaling
        ctx!.imageSmoothingEnabled = true;
        ctx!.imageSmoothingQuality = "high";

        // Draw the original canvas image onto the new scaled canvas
        ctx!.drawImage(canvas, 0, 0, tmpCanvas.width, tmpCanvas.height);

        // Export as high-quality data URL
        return tmpCanvas.toDataURL("image/png", quality);
    }

    const onSignatureConfirm = () => {
        setIsSaving(true)
        setTimeout(async () => {
            try {
                if (signatureURL === undefined || signatureURL === '')
                    showToastrError('Signature cannot be left empty');
                else if (!isEnglandUser() && (validatedIdNumber ?? '') === '')
                    showToastrError('ID Number is required for verification');
                else {
                    let model: DocumentSigningSignDocumentModel = {
                        signingId: signingId,
                        idNumber: validatedIdNumber,
                        signatureContents: signatureURL!.split(';base64,')[1]
                    }

                    let response = await signDocument(model);

                    if (!response.isSuccess) {
                        showToastrErrors(response.errors);
                    } else {
                        setShowConfirmModal(false);
                        setIsSavedSuccessful(true);
                    }
                }
            } catch (e) {
                showToastrError('An unexpected error has occured');
            }

            setIsSaving(false);
        }, 1);
    }

    return (
        <>
            <h1>Document Signing</h1>

            <ErrorBoundary>
                <Loader loading={loading || documentLoading}>
                    <>
                        {validSigningId ?
                            <>
                                {isSavedSuccessful ?
                                    <>
                                        <Alert variant="success">
                                            <p>
                                                Document has been successfully signed
                                            </p>
                                        </Alert>
                                    </>
                                    :
                                    <>
                                        {errorMessages &&
                                            <Alert variant="danger">
                                                <p>
                                                    <ul>
                                                        {errorMessages.map((e, ei) => <li key={ei}>{e}</li>)}
                                                    </ul>
                                                </p>
                                            </Alert>
                                        }
                                        {!isEnglandUser() && !documentSigningDetails &&
                                            <>
                                                {displayRetrievalForm()}
                                            </>
                                        }
                                        {documentSigningDetails &&
                                            <>
                                                {displayDocumentAndSignatureArea()}
                                            </>
                                        }
                                    </>
                                }
                            </>
                            :
                            <>
                                <Alert variant="warning">
                                    <p>
                                        Document signature is either invalid or has already been signed. Try again at a later stage and if the issue persists and you think this is incorrect, kindly contact England Insurance.
                                    </p>
                                </Alert>
                            </>
                        }
                    </>
                </Loader>
                <ConfirmationModal
                    title='Proceed Document Signing'
                    question={<>
                        Are you sure you want to proceed?
                    </>}
                    show={showConfirmModal}
                    saving={isSaving}
                    onConfirm={onSignatureConfirm}
                    onClose={() => {
                        setShowConfirmModal(false);
                    }}
                />
            </ErrorBoundary>
        </>
    );
}

export default connector(ClientDocumentSigning)