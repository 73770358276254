/* eslint-disable react/jsx-no-target-blank */
import React, { FC, Fragment, useEffect, useRef, useState } from 'react'
import * as documentSigningDashboardRedux from '../redux/DocumentSigningDashboardRedux'
import { RootState } from '../../../setup'
import { connect, ConnectedProps, useDispatch } from 'react-redux'
import { Cards } from '../../../@Common/UIComponents/Cards'
import { Button, ButtonGroup, Card, CardGroup, Dropdown, DropdownButton, Nav } from 'react-bootstrap'
import { PageButtons, PageTitle } from '../../../_metronic/layout/core'
import { generatePath, useNavigate } from 'react-router-dom'
import { Loader } from '../../../@Common/UIComponents/Loader'
import { ErrorBoundary } from '../../../@Common/UIComponents/ErrorBoundary'
import { GridFilterDto } from '../../../@Common/Models/GridModel'
import { ColumnDef } from '@tanstack/react-table'
import { CustomColumnDef, Grid } from '../../../@Common/UIComponents/Grid'
import { Buttons } from '../../../@Common/UIComponents/Buttons'
import { Icons } from '../../../@Common/Helpers/Icons'
import { Dictionary } from '../../../@Common/Interfaces/Dictionary'
import { AuthorizeAccess } from '../../../@Common/UIComponents/AuthorizeAccess'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { InsuranceTypes } from '../../subscription/enums/InsuranceTypes'
import { showToastrError, showToastrErrors, showToastrSuccess } from '../../../@Common/Helpers/Toastr'
import { FormGroupComponent } from '../../../@Common/FormComponents/FormGroupComponent'
import { b64toBlob, nameOf } from '../../../@Common/Helpers/Utilities'
import { FormGroupSelect } from '../../../@Common/FormComponents/FormGroupSelect'
import { ButtonSpinner } from '../../../@Common/UIComponents/ButtonSpinner'
import { getInsuranceTypes } from '../../subscriptionRenewal/redux/SubscriptionRenewalActions'
import moment from 'moment'
import { DocumentSigningDashboardPermissions } from '../DocumentSigningDashboardPermissions'
import DocumentSigningModal from './DocumentSigningModal'
import { DocumentSigningRecordModel } from '../models/DocumentSigningRecordModel'
import { cancelDocumentSigningEmail, downloadSignedDocument, getDocumentSigning, getDocumentSigningStatuses, getDocumentTypes, resendDocumentSigningEmail } from '../redux/DocumentSigningDashboardActions'
import * as download from "downloadjs";
import { DocumentSigningStatuses } from '../enums/DocumentSigningStatuses'
import { ConfirmationModal } from '../../../@Common/UIComponents/ConfirmationModal'

const mapState = (state: RootState) => ({ documentSigningDashboard: state.documentSigningDashboard, permissions: state.auth.userPermissions })
const connector = connect(mapState, documentSigningDashboardRedux.actions)
type PropsFromRedux = ConnectedProps<typeof connector>

const DocumentSigningDashboard: FC<PropsFromRedux> = ({ documentSigningDashboard, permissions }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [currentDocumentSigningId, setCurrrentDocumentSigningId] = useState(0)
    const [showSendEmailConfirmation, setShowSendEmailConfirmation] = useState(false)
    const [showCancelConfirmation, setShowCancelConfirmation] = useState(false)
    const [isSaving, setIsSaving] = useState(false);

    let { loading, refreshTable } = documentSigningDashboard;

    useEffect(() => {
    }, [])

    const createNewSigning = () => {
        dispatch(documentSigningDashboardRedux.actions.showDocumentSigningModal());
    }

    const columns = React.useMemo<CustomColumnDef<DocumentSigningRecordModel>[]>(
        () => [
            {
                id: 'fullName',
                accessorKey: 'fullName',
                cell: info => info.getValue(),
                header: () => 'Full Name'
            },
            {
                id: 'policyClaimNumber',
                accessorKey: 'policyClaimNumber',
                cell: info => info.getValue(),
                header: () => 'Policy / Claim Number'
            },
            {
                id: 'idNumber',
                accessorKey: 'idNumber',
                cell: info => info.getValue(),
                header: () => 'ID Number'
            },
            {
                id: 'documentTypeDesc',
                accessorKey: 'documentTypeDesc',
                cell: info => info.getValue(),
                header: () => 'Document Type',
                dataList: getDocumentTypes().map(t => t.label)
            },
            {
                id: 'statusDesc',
                accessorKey: 'statusDesc',
                cell: info => info.getValue(),
                header: () => 'Status',
                dataList: getDocumentSigningStatuses().map(t => t.label)
            },
            {
                id: 'createdDateDesc',
                accessorKey: 'createdDateDesc',
                cell: info => info.getValue(),
                header: () => 'Created Date'
            },
            {
                id: 'sendAsEmail',
                accessorKey: 'sendAsEmail',
                header: () => 'Send As Email',
                enableColumnFilter: false,
                cell: ({ cell }) => (
                    <>
                        {cell.row.original?.sendAsEmail ?
                            <i className={Icons.Check}></i>
                            :
                            <i className={Icons.Times}></i>
                        }
                    </>
                )
            },
            {
                id: 'pageAccessed',
                accessorKey: 'pageAccessed',
                header: () => 'Page Accessed',
                enableColumnFilter: false,
                cell: ({ cell }) => (
                    <>
                        {cell.row.original?.pageAccessed ?
                            <i className={Icons.Check}></i>
                            :
                            <i className={Icons.Times}></i>
                        }
                    </>
                )
            },
            {
                id: 'reminderRetries',
                enableColumnFilter: false,
                accessorKey: 'reminderRetries',
                header: () => 'Reminder Retries',
                cell: ({ cell }) => (
                    <>
                        {cell.row.original?.status === DocumentSigningStatuses.Pending ?
                            cell.row.original?.reminderRetries
                            :
                            <></>
                        }
                    </>
                )
            },
            {
                header: "Actions",
                cell: ({ cell }) => {
                    let row = cell.row.original!;

                    return (
                        <>
                            {row.status == DocumentSigningStatuses.Signed &&
                                <>
                                    <Buttons
                                        variant="primary"
                                        className="m-1 btn-icon"
                                        onClick={() => { showDocument(row.id); }}
                                        icon={Icons.FileDownload}
                                        tooltip="View Document">
                                    </Buttons>
                                </>
                            }
                            {row.status == DocumentSigningStatuses.Pending &&
                                <>
                                    <Buttons
                                        variant="primary"
                                        className="m-1 btn-icon"
                                        onClick={() => { window.open(`/clientDocumentSigning?signingId=${row.guidIdentifier}`, '_blank') }}
                                        icon={Icons.Signature}
                                        tooltip="Sign Document (On-Site)">
                                    </Buttons>
                                    {row.email &&
                                        <Buttons
                                            variant="warning"
                                            className="m-1 btn-icon"
                                            onClick={() => onSendEmail(row.id)}
                                            icon={Icons.Envelope}
                                            tooltip="Send Email">
                                        </Buttons>
                                    }
                                    <Buttons
                                        variant="danger"
                                        className="m-1 btn-icon"
                                        onClick={() => onCancel(row.id)}
                                        icon={Icons.Times}
                                        tooltip="Cancel">
                                    </Buttons>
                                </>
                            }
                        </>
                    );
                }
            },
        ],
        []
    )

    const getData = async (options: GridFilterDto) => {
        var dataResponse = await getDocumentSigning(options);

        if (dataResponse.isSuccess)
            return dataResponse.data!;
        else
            return null;
    }

    const showDocument = async (id: number) => {
        try {
            setIsLoading(true);

            let response = await downloadSignedDocument(id);

            if (!response.isSuccess) {
                showToastrErrors(response.errors);
            } else {
                let file = b64toBlob(response.data?.contents);
                download(file, response.data?.fileName);
            }

            setIsLoading(false);
        } catch (e) {
            showToastrError('An unexpected error has occured');
        }
    }

    const onSendEmail = (id: number) => {
        setCurrrentDocumentSigningId(id);
        setShowSendEmailConfirmation(true)
    }

    const onConfirmSendEmail = () => {
        sendEmail(currentDocumentSigningId);
    }

    const sendEmail = async (id: number) => {
        try {
            setIsSaving(true);

            let response = await resendDocumentSigningEmail(id);

            if (!response.isSuccess) {
                showToastrErrors(response.errors);
            } else {
                showToastrSuccess("Email has been successfully sent");
                setShowSendEmailConfirmation(false);
                setCurrrentDocumentSigningId(0);
            }

            setIsSaving(false);
        } catch (e) {
            showToastrError('An unexpected error has occured');
        }
    }

    const onCancel = (id: number) => {
        setCurrrentDocumentSigningId(id);
        setShowCancelConfirmation(true)
    }

    const onConfirmCancel = () => {
        cancel(currentDocumentSigningId);
    }

    const cancel = async (id: number) => {
        try {
            setIsSaving(true);

            let response = await cancelDocumentSigningEmail(id);

            if (!response.isSuccess) {
                showToastrErrors(response.errors);
            } else {
                showToastrSuccess("Document Signing has been successfully cancelled");
                dispatch(documentSigningDashboardRedux.actions.refreshTable());
                setShowCancelConfirmation(false);
                setCurrrentDocumentSigningId(0);
            }

            setIsSaving(false);
        } catch (e) {
            showToastrError('An unexpected error has occured');
        }
    }

    const onRefresh = async () => {
        setTimeout(async () => {
            dispatch(documentSigningDashboardRedux.actions.refreshTable());
        }, 1);
    }

    return (
        <>
            <ErrorBoundary>
                <PageTitle>Document Signing Dashboard</PageTitle>
                <PageButtons>
                    <AuthorizeAccess permissionKey={DocumentSigningDashboardPermissions.CreateSigning}>
                        <Buttons variant="primary" className="m-1" onClick={createNewSigning} icon={Icons.Plus}>Create Signing</Buttons>
                    </AuthorizeAccess>
                </PageButtons>
                <Loader loading={isLoading} />
                <div className='d-grid gap-2 d-md-flex justify-content-md-end'>
                    <Buttons
                        variant="light"
                        className="m-1 btn-icon"
                        onClick={onRefresh}
                        icon={Icons.Sync}
                        tooltip="Refresh">
                    </Buttons>
                </div>
                <Grid<DocumentSigningRecordModel>
                    columns={columns}
                    getData={(options) => getData(options)}
                    maxPageSize={20}
                    refresh={refreshTable}
                />
                <DocumentSigningModal />
                <ConfirmationModal
                    title='Re-Send Email'
                    question={<>
                        Are you sure you want to re-send the signing email to this client?
                    </>}
                    show={showSendEmailConfirmation}
                    saving={isSaving}
                    onConfirm={onConfirmSendEmail}
                    onClose={() => {
                        setShowSendEmailConfirmation(false);
                    }}
                />
                <ConfirmationModal
                    title='Cancel Document Signing'
                    question={<>
                        Are you sure you want to cancel this document signing?
                    </>}
                    show={showCancelConfirmation}
                    saving={isSaving}
                    onConfirm={onConfirmCancel}
                    onClose={() => {
                        setShowCancelConfirmation(false);
                    }}
                />
            </ErrorBoundary>
        </>
    );
}

export default connector(DocumentSigningDashboard)