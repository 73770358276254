export class FileModel {
    fileName: string;
    fileSize: number;
    contents: string;

    constructor(fN: string, fS: number, c: string) {
        this.fileName = fN;
        this.fileSize = fS;
        this.contents = c;
    }
}