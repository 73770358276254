import { getFromServerAPI, postToServerAPI } from "../../../@Common/Helpers/ApiHelper";
import { PaginatedServiceResponse, ServiceResponse } from "../../../@Common/Interfaces/ServiceResponse";
import { FileModel } from "../../../@Common/Models/FileModel";
import { GridFilterDto } from "../../../@Common/Models/GridModel";
import { OptionModel } from "../../../@Common/Models/OptionModel";
import { DocumentSigningStatuses } from "../enums/DocumentSigningStatuses";
import { SigningDocumentTypes } from "../enums/SigningDocumentTypes";
import { DocumentSigningReadModel } from "../models/DocumentSigningReadModel";
import { DocumentSigningRecordModel } from "../models/DocumentSigningRecordModel";
import { DocumentSigningWriteModel } from "../models/DocumentSigningWriteModel";

const API_URL = process.env.REACT_APP_API_URL + "/api/v1/documentSigning";

export const CREATE_DOCUMENT_SIGNING_URL = `${API_URL}/CreateDocumentSigning`;
export const GET_DOCUMENT_SIGNINGS_URL = `${API_URL}/GetDocumentSignings`;
export const GET_LATEST_DOCUMENT_SIGNING_BY_CLIENT_DETAILS_URL = `${API_URL}/GetLatestDocumentSigningByClientDetails`;
export const GET_SIGNED_DOCUMENT_URL = `${API_URL}/GetSignedDocument`;
export const RESEND_DOCUMENT_SIGNING_EMAIL_URL = `${API_URL}/ReSendDocumentSigningEmail`;
export const CANCEL_DOCUMENT_SIGNING_URL = `${API_URL}/CancelDocumentSigning`;

export const createDocumentSigning = async (model: DocumentSigningWriteModel) => {
  let response = await postToServerAPI<ServiceResponse<string>>(CREATE_DOCUMENT_SIGNING_URL, model);
  return response;
}

export const getDocumentSigning = async (filter: GridFilterDto) => {
  let response = await postToServerAPI<PaginatedServiceResponse<DocumentSigningRecordModel>>(GET_DOCUMENT_SIGNINGS_URL, filter);
  return response;
}

export const getLatestDocumentSigningByClientDetails = async (policyClaimNumber: string) => {
  let response = await postToServerAPI<ServiceResponse<DocumentSigningReadModel>>(GET_LATEST_DOCUMENT_SIGNING_BY_CLIENT_DETAILS_URL, { policyClaimNumber });
  return response;
}

export const downloadSignedDocument = async (id: number) => {
    let response = await postToServerAPI<ServiceResponse<FileModel>>(GET_SIGNED_DOCUMENT_URL, { id });
    return response;
}

export const resendDocumentSigningEmail = async (id: number) => {
  let response = await postToServerAPI<ServiceResponse>(RESEND_DOCUMENT_SIGNING_EMAIL_URL, { id });
  return response;
}

export const cancelDocumentSigningEmail = async (id: number) => {
  let response = await postToServerAPI<ServiceResponse>(CANCEL_DOCUMENT_SIGNING_URL, { id });
  return response;
}

export const getDocumentTypes = () => {
  return [
      { value: SigningDocumentTypes.ProposalForm, label: 'Proposal Form' },
      { value: SigningDocumentTypes.MeetingForm, label: 'Meeting Form' },
      { value: SigningDocumentTypes.ClaimForm, label: 'Claim Form' },
      { value: SigningDocumentTypes.Other, label: 'Other' }
  ];
}

export const getDocumentSigningStatuses = () => {
  return [
      { value: DocumentSigningStatuses.Pending, label: 'Pending' },
      { value: DocumentSigningStatuses.Signed, label: 'Signed' },
      { value: DocumentSigningStatuses.Cancelled, label: 'Cancelled' }
  ];
}