import { lazy, FC, Suspense, useEffect } from 'react'
import { Route, Routes, Navigate, useNavigate, useLocation } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { getCSSVariableValue } from "../../_metronic/assets/ts/_utils";
import { HomeWrapper } from '../core/home/HomeWrapper';
import { useSelector } from 'react-redux';
import { RootState } from '../../setup';
import { MenuItemModel } from '../../_metronic/layout/components/aside/models/MenuItemModel';
import { SuspensedView } from '../../_metronic/layout/SuspendedView';
import AuditLogsPage from '../core/auditLogs/AuditLogsPage';
import DocumentSigningDashboardPage from '../documentSigningDashboard/DocumentSigningDashboardPage';

const PrivateRoutes = () => {
  const PeoplePage = lazy(() => import('../core/people/PeoplePage'))
  const ProfilePage = lazy(() => import('../core/profile/ProfilePage'))
  const SubscriptionPage = lazy(() => import('../subscription/SubscriptionPage'))
  const SubscriptionDashboardPage = lazy(() => import('../subscriptionDashboard/SubscriptionDashboardPage'))
  const SubscriptionRenewalDashboardPage = lazy(() => import('../subscriptionRenewalDashboard/SubscriptionRenewalDashboardPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Home after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/home' />} />
        {/* Pages */}
        <Route path='home' element={<HomeWrapper />} />
        {/* Lazy Modules */}
        <Route
          path='people/*'
          element={
            <AuthorizedPage>
              <SuspensedView>
                <PeoplePage />
              </SuspensedView>
            </AuthorizedPage>
          }
        />
        <Route
          path='profile/*'
          element={
              <SuspensedView>
                <ProfilePage />
              </SuspensedView>
          }
        />
        <Route
          path='auditLogs/*'
          element={
            <SuspensedView>
              <AuditLogsPage />
            </SuspensedView>
          }
        />
        <Route
          path='subscription/*'
          element={
            <AuthorizedPage>
              <SuspensedView>
                <SubscriptionPage />
              </SuspensedView>
            </AuthorizedPage>
          }
        />
        <Route
          path='subscriptionDashboard/*'
          element={
            <AuthorizedPage>
              <SuspensedView>
                <SubscriptionDashboardPage />
              </SuspensedView>
            </AuthorizedPage>
          }
        />
        <Route
          path='subscriptionRenewalDashboard/*'
          element={
            <AuthorizedPage>
              <SuspensedView>
                <SubscriptionRenewalDashboardPage />
              </SuspensedView>
            </AuthorizedPage>
          }
        />
        <Route
          path='documentSigningDashboard/*'
          element={
            <AuthorizedPage>
              <SuspensedView>
                <DocumentSigningDashboardPage />
              </SuspensedView>
            </AuthorizedPage>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const AuthorizedPage: FC = ({ children }) => {
  const menuItems = useSelector<RootState, MenuItemModel[]>(({ menu }) => menu.menuItems!)
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    let path = location.pathname;
    let pathKeys = path.split('/').filter(p => p != '');
    let mainPathKey = pathKeys[0];
    const hasAccess = menuItems.findIndex(mi => mi.location == mainPathKey) > -1;

    if (!hasAccess)
      navigate('/error/401');

  }, [location.pathname])

  return <>{children}</>
}

export { PrivateRoutes }