/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import { FC } from 'react'
import { Routes, Route, BrowserRouter, Navigate, useLocation } from 'react-router-dom'
import { shallowEqual, useSelector } from 'react-redux'
import { PrivateRoutes } from './PrivateRoutes'
import { ErrorsPage } from '../core/errors/ErrorsPage'
import { Logout, AuthPage } from '../core/auth'
import { RootState } from '../../setup'
import { App } from '../App'
import OnlinePaymentPage from '../core/onlinePayment/OnlinePaymentPage'
import { SuspensedView } from '../../_metronic/layout/SuspendedView'
import SubscriptionRenewalClientPage from '../subscriptionRenewalClient/SubscriptionRenewalClientPage'
import DocumentSigningClientPage from '../documentSigningClient/DocumentSigningClientPage'

const { PUBLIC_URL } = process.env

const AppRoutes: FC = () => {
  const isAuthorized = useSelector<RootState>(({ auth }) => auth.user, shallowEqual)
  const hasPermissions = useSelector<RootState>(({ auth }) => (auth.userPermissions?.length ?? 0) > 0, shallowEqual)

  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='logout' element={<Logout />} />
          {isAuthorized && hasPermissions ? (
            <>
              <Route path='/*' element={<PrivateRoutes />} />
              <Route index element={<Navigate to='/home' />} />
            </>
          ) : (
            <>
              <Route path='auth/*' element={<AuthPage />} />
              <Route path='*' element={<Navigate to='/auth' />} />
            </>
          )}
          <Route
            path='onlinePayment/*'
            element={
              <SuspensedView>
                <OnlinePaymentPage />
              </SuspensedView>
            }
          />
          <Route
            path='clientRenewal/*'
            element={
              <SuspensedView>
                <SubscriptionRenewalClientPage />
              </SuspensedView>
            }
          />
          <Route
            path='clientDocumentSigning/*'
            element={
              <SuspensedView>
                <DocumentSigningClientPage />
              </SuspensedView>
            }
          />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export { AppRoutes }
